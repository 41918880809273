import {style} from "./style";
import React, {useEffect, useState} from "react";

export type FilterByThirdTabProps = {
    active: boolean,
    onStop: () => void
}

function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
}

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

export const FilterByThirdTab = ({active, children, onStop}: React.PropsWithChildren<FilterByThirdTabProps>) => {
    const {width} = useWindowDimensions();

    return (
        <div style={active ? style.boxAnimate : style.box}>
            <div style={active ? style.box2Active : style.box}>
                <div style={{...style.content, width: `${Math.max(Math.min(width - 360 - 340, 675), 280)}px`}}>
                    {children}
                </div>
                <div onClick={onStop} style={{width: "calc(100% - 675px)"}}/>
            </div>
        </div>
    )
}