import CSS from "csstype";
import {colors} from "../../../common/colors";

export const style: { [key: string]: CSS.Properties } = {
    box: {
        // paddingTop: "80px",
        flex: "1",
        overflowY: "scroll",
    },
    noScrollContainer: {
        // paddingTop: "80px",
        overflow: "hidden",
        // height: "100%",
        flex: "1"

    },
    header: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        padding: "32px",
        paddingBottom: "8px",
        justifyContent: "space-between",
        alignItems: "center",
        margin: 0
    },
    title: {
        margin: 0,
        fontWeight: 700,
        fontSize: "32px",
        color: colors.supportBlack
    },
    postNumber: {
        fontWeight: 400,
        fontSize: "16px",
        color: colors.supportDarker,
        marginLeft: "16px"
    },
    newPost: {
        // backgroundColor: colors.primaryLight,
        backgroundColor: colors.darkGray,
        color: colors.supportWhite,
        border: "none",
        outline: "none",
        fontFamily: "'Literata', serif",
        padding: "12px 16px 12px 16px",
        borderRadius: "8px",
        fontWeight: 600,
        fontSize: "16px",
        cursor: "pointer"
    },
    subtitle: {
        padding: "0 32px 32px 32px",
        width: "100%",
        margin: 0
    }
}