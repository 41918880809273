import {Filters} from "./types";

export function FilterConverter(filters: Filters): string {
    let filtersConverted = ""
    for (const key of Object.keys(filters)) {
        if (filters[key].length > 0) {
            let parsedKey = key
            if (key === "topic_tree")
                parsedKey = "topics"
            else if (key === "publicationPeriods")
                parsedKey = "publicationPeriod"

            if (key === "keywords") {
                for (const values of filters[key]) {
                    if (filtersConverted === "") {
                        filtersConverted += `${parsedKey}=${values}`
                    } else {
                        filtersConverted += `&${parsedKey}=${values}`
                    }
                }
            } else {
                if (filtersConverted === "") {
                    filtersConverted += `${parsedKey}=`
                } else {
                    filtersConverted += `&${parsedKey}=`
                }
                for (let i = 0; i < filters[key].length; i++) {
                    if (i + 1 === filters[key].length) {
                        if (key === "keywords") {
                            filtersConverted += filters[key][i]
                        } else {
                            filtersConverted += filters[key][i].id
                        }
                    } else {
                        if (key === "keywords") {
                            filtersConverted += filters[key][i] + ","
                        } else {
                            filtersConverted += filters[key][i].id + ","
                        }
                    }
                }
            }
        }
    }

    return filtersConverted
}