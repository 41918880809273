import {style} from "./style";
import {Oval} from "react-loader-spinner";
import {colors} from "../colors";
import React from "react";

type Props = {
    size?: number
    color?: string
    secondaryColor?: string
}
export default function Loader({size, color, secondaryColor}: Props) {
    return (
        <div style={style.box}>
            <Oval
                height={size ? size : 30}
                width={size ? size : 30}
                color={color ? color : colors.supportDarker}
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor={secondaryColor ? secondaryColor : colors.supportBlack}
                strokeWidth={8}
                strokeWidthSecondary={8}/>
        </div>
    )
}