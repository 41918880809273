import React, {useEffect} from "react";
import {Col, Row} from "react-bootstrap";
import Post from "./Post/Post";
import {style} from "./style"
import ViewPost from "./ViewPost/ViewPost";
import TokenManager from "../../../common/TokenManager";
import {PostsResponse} from "../discovery/type";
import {PostResult} from "./ViewPost/Results/PostResults";
import Toast, {ApiError} from "../../../common/Toast";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileCirclePlus} from "@fortawesome/free-solid-svg-icons";
import {colors} from "../../../common/colors";
import FancyLoader from "../../../common/FancyLoader/FancyLoader";
import Loader from "../../../common/Loader/Loader";
import {useAppSelector} from "../../../common/Redux/hooks";

type PostType = {
    documentId: string,
    title: string,
    body: string,
    createdAt: string,
    lastUpdatedAt: string
}

export type SettingType = {
    key: string,
    tag: string
}

export default function SmartTargeting() {
    const [refresh, setRefresh] = React.useState<boolean>(true)
    const [visible, setVisible] = React.useState<boolean>(false)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [myPosts, setMyPosts] = React.useState<PostType[]>([])
    const [selectedPost, setSelectedPost] = React.useState<PostResult | undefined>(undefined)
    const navigate = useNavigate();
    const userAuth = useAppSelector((state) => state.userAuthorizations.value)

    useEffect(() => {
        if (refresh)
            getPosts().then(() => setRefresh(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible])

    const selectPost = (post: PostResult, event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation()
        event.preventDefault();
        setVisible(true)
        setSelectedPost(post)
    }

    const setVisibility = () => {//true if new post, false if results
        setSelectedPost(undefined)
        setVisible(!visible)
    }

    const getPosts = async () => {
        setLoading(true)
        const token = await TokenManager.getAccessToken()
        if (token === null) {
            navigate("/login")
            return
        }
        let postsFetch = await fetch(`${process.env.REACT_APP_BASE_URL}/setting/?tag=post`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        })
        if (!postsFetch.ok) {
            setLoading(false)
            const error: ApiError = await postsFetch.json()
            Toast(error)
            return
        }
        let posts: (SettingType & { value: { documentId: string } })[] = await postsFetch.json()
        let postIds = posts.map((e) => e.value?.documentId).filter(n => n).join(",");
        if (postIds.length > 0) {
            let fetched = await fetch(`${process.env.REACT_APP_BASE_URL_ANALYTICS}/smart-targeting/document/?documentId=${postIds}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'clientId': `0`,
                }
            })
            setLoading(false)
            if (!fetched.ok) {
                const error: ApiError = await fetched.json()
                Toast(error)
                return
            }
            const data: (PostsResponse & { results: PostType[] }) = await fetched.json()
            let sorted = data.results.sort((a, b) => {
                return (new Date(b.lastUpdatedAt).getTime() - (new Date(a.lastUpdatedAt)).getTime())
            })
            setMyPosts(sorted)
        } else {
            setMyPosts([])
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!userAuth.includes(20) && userAuth.length > 0)
            navigate("/media-analytics")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userAuth])

    return (
        <div style={!visible ? style.box : style.noScrollContainer}>
            {userAuth.includes(20) ? <>
            <ViewPost postResult={selectedPost} visible={visible} setVisible={setVisibility}
                      setRefresh={() => setRefresh(true)}/>
            <Row style={style.header}>
                <Col className={"p-0"}>
                    <div style={{display: "flex", alignItems: "center", height: "48px"}}>
                        <h3 style={style.title}>{"Smart Targeting"}</h3>
                        <span style={style.postNumber}>
                                    {`${myPosts.length} ${myPosts.length > 1 ? "Posts" : "Post"}`}
                                </span>
                    </div>
                </Col>
                <Col style={{display: "flex", justifyContent: "flex-end"}} className={"p-0"}>
                    {myPosts.length > 0 ?
                        <button style={style.newPost} onClick={() => setVisible(true)}>
                            {"Nuova comunicazione"}
                        </button>
                        : null}
                </Col>
            </Row>
            <Row style={style.subtitle}>
                {"Carica il messaggio che vuoi diffondere e scopri i giornalisti, i content creator e le testate più rilevanti, in base al testo che hai scritto"}
            </Row>
            {myPosts.length > 0 ?
                myPosts.map((post, index) =>
                    <Post key={`smart-targeting-post-${index}`}
                          onClick={(event) => selectPost(post, event)}
                          category={""}
                          content={post.body} date={post.lastUpdatedAt} title={post.title}
                          setRefresh={() => getPosts()} id={post.documentId}/>)
                : loading ?
                    <div style={{height: "calc(100vh - 400px)", display: "flex", alignItems: "center"}}>
                        <FancyLoader/>
                    </div>
                    : <div className={"d-flex justify-content-center align-items-center flex-column mt-5 p-4"}
                           style={{
                               border: `dashed 2px ${colors.supportDark}`,
                               marginLeft: "32px",
                               marginRight: "32px",
                               borderRadius: "16px",
                               cursor: "pointer"
                           }}
                           onClick={() => setVisible(true)}>
                        <FontAwesomeIcon
                            icon={faFileCirclePlus}
                            style={{height: "80px", marginBottom: "16px"}}
                            size={"2xl"}
                            color={colors.primaryLight}/>
                        <p style={style.noResult}>
                            {"Inizia a usare lo Smart Targeting: carica il messaggio che vuoi diffondere e scopri i content creator e le testate più rilevanti, in base al testo che hai scritto."}
                        </p>
                    </div>}
            </>
                : userAuth.length === 0 ? <Loader/>
                    : null}
        </div>
    );
}