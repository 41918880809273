import {Datapoint} from "../../MediaAnalytics";
import {Cell, Legend, Pie, PieChart, ResponsiveContainer,} from "recharts";
import React, {useEffect} from "react";
import {colors} from "../../../../../common/colors";

type Props = {
    datapoints: Datapoint[]
}

type Data = {
    name: string,
    value: number
}[]

// const donutColors = ["#ee9a3a", colors.primaryLight, colors.primaryLightOpacity]
const donutColors = [colors.cyan, colors.magenta, colors.darkGray]
const donutColors2 = [colors.darkGray, colors.magenta]

export default function Donut({datapoints}: Props) {

    const [data, setData] = React.useState<Data>([])

    useEffect(() => {
        const newData = []
        for (const datapoint of datapoints) {
            newData.push({
                name: datapoint["series"],
                value: datapoint["y-value"]
            })
        }
        setData(newData)
    }, [datapoints])

    const renderCustomizedLabel = ({
                                       cx,
                                       cy,
                                       midAngle,
                                       innerRadius,
                                       outerRadius,
                                       percent,
                                       index
                                   }: any, white: number[]) => {
        const RADIAN = Math.PI / 180;
        const radius = innerRadius + (outerRadius - innerRadius) * 0.3;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill={white.includes(index) ? "white" : "black"} textAnchor={x > cx ? 'start' : 'end'}
                  fontSize={"16px"}
                  dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    return (
        <ResponsiveContainer width="100%" height="90%">
            <PieChart width={300} height={300}>
                <Pie data={data} dataKey="value" cx="50%" cy="50%" innerRadius={50}
                     outerRadius={110} labelLine={false}
                     label={(params) => renderCustomizedLabel(params, data.length > 2 ? [2, 1] : [0, 1])}>
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={data.length > 2 ? donutColors[index] : donutColors2[index]}/>
                    ))}
                </Pie>
                <Legend formatter={(value) => <span style={{color: "#000000"}}>{value}</span>}/>
            </PieChart>
        </ResponsiveContainer>
    )
}