import {Datapoint} from "../../MediaAnalytics";
import {Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis, Text} from "recharts";
import React, {useEffect} from "react";
import {colors} from "../../../../../common/colors";

type Props = {
    datapoints: Datapoint[]
}
// const graphColors = ["#ED5565", "#FFCE54", "#48CFAD", "#5D9CEC", "#EC87C0", "#CCD1D9", "#FC6E51", "#A0D468", "#4FC1E9", "#AC92EC", "#F5F7FA", "#656D78"]

// const graphColors = ["#C02323", "#DE542C", "#EF7E32", "#EE9A3A", "#EABD3B", "#E7E34E", "#F7F4BF"]

export default function Histogram({datapoints}: Props) {

    const [data, setData] = React.useState<any>([])
    const CustomizedAxisTick = ({x, y, payload}: any) => {
        if (payload && payload.value) {
            return (<Text fontSize={"12px"} width={"12px"} x={x} y={y} textAnchor="middle"
                          verticalAnchor="start">{payload.value}</Text>);
        }
        return null;
    };


    useEffect(() => {
        const newData = []
        let sortedDatapoints = datapoints.sort((a, b) => {
            if (a["y-value"] < b["y-value"])
                return 1
            else if (a["y-value"] > b["y-value"])
                return -1
            return 0
        })
        for (const datapoint of sortedDatapoints) {
            let auxData: { [key: string]: number | string } = {
                x: datapoint["x-value-label"],
                valore: datapoint["y-value"]
            }
            newData.push(auxData)
        }
        setData(newData)
    }, [datapoints])

    return (
        <ResponsiveContainer width="100%" height="90%">
            <BarChart width={700} height={400} data={data}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="x" angle={0} height={50} allowDataOverflow={false} interval={0}
                       tick={<CustomizedAxisTick/>}/>
                {/*<XAxis dataKey="x" angle={-45} textAnchor="end" height={150} allowDataOverflow={false} interval={0} />*/}
                <YAxis tickFormatter={(tick) => {
                    return `${tick}%`;
                }}/>
                <Tooltip/>
                <Bar dataKey={"valore"}>
                    {datapoints.map((datapoint, index) => <Cell key={`cell-${index}`} fill={colors.darkGray}/>)}
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    )
}