import {useEffect, useState} from "react";
import {Filters} from "../../FilterByNew/types";
import {filterTranslation} from "../../FilterByNew/FilterByNew";

export default function useAssembleFilterPhrase(filters: Filters) {
    const [phrase, setPhrase] = useState<string>("")
    useEffect(() => {
        let newPhrase = ""
        for (const filterKey of Object.keys(filters)) {
            if (filterKey !== "publicationPeriod" && filterKey !== "publicationPeriods" && filters[filterKey].length > 0) {

                if (filterKey !== "geoCategories" && filterKey !== "keywords") {
                    switch (filters[filterKey].length) {
                        case 1:
                            newPhrase += `${filters[filterKey][0].name}, `
                            break
                        case 2:
                            newPhrase += `${filters[filterKey][0].name}, ${filters[filterKey][1].name}, `
                            break
                        default:
                            newPhrase += `${filters[filterKey][0].name} + ${filters[filterKey].length - 1} ${filterTranslation[filterKey].toLowerCase()}, `
                    }
                }
                if (filterKey === "keywords") {
                    switch (filters[filterKey].length) {
                        case 1:
                            newPhrase += `${filters[filterKey][0]}, `
                            break
                        case 2:
                            newPhrase += `${filters[filterKey][0]}, ${filters[filterKey][1]}, `
                            break
                        default:
                            newPhrase += `${filters[filterKey][0]} + ${filters[filterKey].length - 1} ${filterTranslation[filterKey].toLowerCase()}, `
                    }
                }
            }
        }
        if (filters["geoCategories"].length > 0) {
            switch (filters["geoCategories"].length) {
                case 1:
                    if (filters["geoCategories"][0].name === "Nazionale") {
                        newPhrase += `${filters["geoCategories"][0].name}`
                        break
                    }
                    newPhrase += `${filters["geoCategories"][0].subname}, `
                    break
                case 2:
                    newPhrase += `${filters["geoCategories"][0].subname}, ${filters["geoCategories"][1].subname}, `
                    break
                default:
                    newPhrase += `${filters["geoCategories"][0].subname} + ${filters["geoCategories"].length - 1} Regioni, `
            }
        }
        if (newPhrase.substring(newPhrase.length - 2) === ", ")
            newPhrase = newPhrase.substring(0, newPhrase.length - 2)
        setPhrase(newPhrase)
    }, [filters])
    return phrase
}