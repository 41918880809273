import {style} from "./style";
import React, {useEffect} from "react";
import TokenManager from "../../common/TokenManager";
import {useNavigate} from "react-router-dom";
import {Filters} from "../home/FilterByNew/types";
import {FilterConverter} from "../home/FilterByNew/FilterManager";
import Loader from "../../common/Loader/Loader";
import {colors} from "../../common/colors";

type Props = {
    filters: Filters,
    biggestPeriod: number
}
export default function DownloadMediaAnalytics({filters, biggestPeriod}: Props) {
    const [userAuth, setUserAuth] = React.useState<number[]>([])
    const [loadingExcel, setLoadingExcel] = React.useState<boolean>(false)
    const navigate = useNavigate();

    const createExcel = async () => {
        const token = await TokenManager.getAccessToken()
        if (token === null)
            navigate("/login")
        setLoadingExcel(true)
        let params = FilterConverter(filters)
        let fetched = await fetch(`${process.env.REACT_APP_BASE_URL_ANALYTICS}/media-analytics/${params === "" ? `?publicationPeriod=${biggestPeriod}` : `?${params}`}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'clientId': `0`,
                },
            })
        const contentDisposition = fetched.headers.get('Content-Disposition')
        let fileName = "media_analytics"
        if (contentDisposition) {
            const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
            const matches = fileNameRegex.exec(contentDisposition)
            if (matches != null && matches[1]) {
                fileName = matches[1].replace(/['"]/g, '')
            }
        }
        const blob = await fetched.blob()
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
        setLoadingExcel(false)
    }

    const getUserData = async () => {
        const token = await TokenManager.getAccessToken()
        if (token === null)
            navigate("/login")
        let fetched = await fetch(`${process.env.REACT_APP_BASE_URL}/auth/user`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        })
        if (!fetched.ok) {
            return
        }
        let user: {
            authorizations: number[]
        } = await fetched.json()
        setUserAuth(user.authorizations)
    }
    useEffect(() => {
        getUserData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!userAuth.includes(40))
        return null
    return (
        <button
            style={loadingExcel ? style.disabledPrimary : style.activePrimary}
            disabled={loadingExcel}
            onClick={createExcel}>
            {"Download Excel"}
            {loadingExcel && <Loader color={colors.supportWhite} secondaryColor={colors.supportLight} size={10}/>}
        </button>
    )
}