import React, {Dispatch, useEffect, useMemo} from "react";
import {Row} from "react-bootstrap";
import {style} from "../style";
import InterestCheckBox from "../../../../../loginForm/InterestCheckBox/InterestCheckBox";
import TokenManager from "../../../../../../common/TokenManager";
import {Filters, SelectionOptionsResponse} from "../../../../FilterByNew/types";
import Toast, {ApiError} from "../../../../../../common/Toast";
import {useNavigate} from "react-router-dom";
import {FilterContent} from "../../../../FilterByNew/FilterByNew";

type Props = {
    onFilterChange: (media: FilterContent[], title: FilterContent[], geo: FilterContent[], publication: FilterContent) => void
    setLocalGeo: Dispatch<FilterContent[]>
    setIsLocal: Dispatch<boolean>
    isLocal: boolean
    media?: FilterContent[]
    geo?: FilterContent[]
    title?: FilterContent[]
    publication?: FilterContent
}
export default function PostFilters(
    {
        onFilterChange,
        media,
        geo,
        publication,
        title,
        setLocalGeo,
        setIsLocal,
        isLocal
    }: Props) {
    const [mediaObject, setMediaObject] = React.useState<FilterContent[]>(media ? media : [])
    const [titleObject, setTitleObject] = React.useState<FilterContent[]>(title ? title : [])
    const [geoObject, setGeoObject] = React.useState<FilterContent[]>(geo ? geo : [])
    const [publicationObject, setPublicationObject] = React.useState<FilterContent | undefined>(publication ? publication : undefined)
    const [filters, setFilters] = React.useState<Filters | undefined>(undefined)
    const navigate = useNavigate();

    useEffect(() => {
        getFilters()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const biggestPeriod = useMemo(() => {
        if (filters !== undefined) {
            const biggest = filters["publicationPeriods"].sort((a, b) => b.id - a.id)[0]
            setPublicationObject(biggest)
            return biggest.id
        }
        return 1
    }, [filters])


    const getFilters = async () => {
        const token = await TokenManager.getAccessToken()
        if (token === null)
            navigate("/login")
        let fetched = await fetch(`${process.env.REACT_APP_BASE_URL_ANALYTICS}/selection-options`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'clientId': `0`,
            }
        })
        if (!fetched.ok) {
            let data: ApiError = await fetched.json()
            Toast(data)
            return
        }
        let data: SelectionOptionsResponse = await fetched.json()
        setFilters(data.results)
        setLocalGeo(data.results["geoCategories"].filter((filter) => filter.name === "Locale"))
    }

    const handleMedia = (filter: FilterContent) => {
        let newMedia = mediaObject
        let index = newMedia.findIndex((media) => media.id === filter.id)
        if (index === -1) {
            newMedia = [...mediaObject, filter]
        } else {
            let mediaCopy = [...mediaObject]
            mediaCopy.splice(index, 1)
            newMedia = mediaCopy
        }
        setMediaObject(newMedia)
        if (publicationObject) {
            onFilterChange(newMedia, titleObject, geoObject, publicationObject)
        }
    }

    const handleTitle = (filter: FilterContent) => {
        let newTitle = titleObject
        let index = newTitle.findIndex((title) => title.id === filter.id)
        if (index === -1) {
            newTitle = [...titleObject, filter]
        } else {
            let titleCopy = [...titleObject]
            titleCopy.splice(index, 1)
            newTitle = titleCopy
        }
        setTitleObject(newTitle)
        if (publicationObject) {
            onFilterChange(mediaObject, newTitle, geoObject, publicationObject)
        }
    }

    const handleGeo = (filter: FilterContent) => {
        let newGeo = geoObject
        if (filter.name === "Nazionale" && filters !== undefined) {
            let national = newGeo.findIndex((geo) => geo.name === "Nazionale")
            if (national === -1) {
                let nationalIndex = filters["geoCategories"].findIndex((geo) => geo.name === "Nazionale")
                newGeo = [filters["geoCategories"][nationalIndex]]
            } else {
                newGeo = []
            }
            setIsLocal(false)
        } else {
            if (filter.subname !== undefined) {
                let index = geoObject.findIndex((geo) => geo.subname === filter.subname)
                if (index === -1) {
                    newGeo = [...geoObject, filter]
                } else {
                    let geoCopy = [...geoObject]
                    geoCopy.splice(index, 1)
                    newGeo = geoCopy
                }
            } else {
                if (isLocal) {
                    setIsLocal(false)
                } else {
                    setIsLocal(true)
                }
                newGeo = []
            }
        }
        setGeoObject(newGeo)
        if (publicationObject) {
            onFilterChange(mediaObject, titleObject, newGeo, publicationObject)
        }
    }

    const handlePublication = (filter: FilterContent) => {
        setPublicationObject(filter)
        onFilterChange(mediaObject, titleObject, geoObject, filter)
    }

    return (
        <>{filters !== undefined ? <div style={{marginRight: "5px"}}>
            <Row className={"m-0 w-100"}>
                <h3 style={style.bodyTitle}>{"Periodo di pubblicazione"}</h3>
            </Row>
            <Row className={"m-0 w-100"}>
                {filters["publicationPeriods"].map((publication, index) =>
                    <InterestCheckBox key={`publicationPeriods-${index}`} text={publication.name}
                                      checked={publicationObject === undefined ? biggestPeriod === publication.id : publicationObject.id === publication.id}
                                      handleOnChange={() => handlePublication(publication)}/>)}
            </Row>
            <Row className={"m-0 w-100"}>
                <h3 style={style.bodyTitle}>{"Tipologia di media"}</h3>
            </Row>
            <Row className={"m-0 w-100"}>
                {filters["mediaTypes"].map((mediaFilter, index) =>
                    <InterestCheckBox key={`mediaFilter-${index}`} text={mediaFilter.name}
                                      checked={(mediaObject.map((media) => media.id)).includes(mediaFilter.id)}
                                      handleOnChange={() => handleMedia(mediaFilter)}/>)}
            </Row>
            <Row className={"m-0 w-100"}>
                <h3 style={style.bodyTitle}>{"Profilo della testata"}</h3>
            </Row>
            <Row className={"m-0 w-100"}>
                {filters["sourceProfiles"].map((titleFilter, index) =>
                    <InterestCheckBox key={`titleFilter-${index}`} text={titleFilter.name}
                                      checked={(titleObject.map((title) => title.id)).includes(titleFilter.id)}
                                      handleOnChange={() => handleTitle(titleFilter)}/>)}
            </Row>
            <Row className={"m-0 w-100"}>
                <h3 style={style.bodyTitle}>{"Copertura geografica"}</h3>
            </Row>
            <Row className={"m-0 w-100"}>
                <InterestCheckBox text={"Nazionale"} checked={(geoObject.map((geo) => geo.name)).includes("Nazionale")}
                                  handleOnChange={() => handleGeo(filters["geoCategories"][filters["geoCategories"].findIndex((geo) => geo.name === "Nazionale")])}/>
                <InterestCheckBox text={"Locale"} checked={isLocal}
                                  handleOnChange={() => handleGeo({id: 0, name: "Locale"})}/>
                <Row>
                    {isLocal ? filters["geoCategories"].map((geoFilter, index) => {
                        if (geoFilter.name === "Locale" && geoFilter.subname !== undefined) {
                            return <InterestCheckBox key={`geoCategories-${index}`} text={geoFilter.subname}
                                                     checked={(geoObject.map((geo) => geo.subname)).includes(geoFilter.subname)}
                                                     handleOnChange={() => handleGeo(geoFilter)}/>
                        }
                        return null
                    }) : null}
                </Row>
            </Row>
        </div> : null}</>
    );
}