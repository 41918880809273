import CSS from "csstype";
import {colors} from "../../../../common/colors";

export const style: { [key: string]: CSS.Properties } = {
    box: {
        position: "relative",
        width: "198px",
        marginRight:"32px",
        height:"43px",
        // marginRight:"32px"
    },
    logo: {
      height:"100%",
    },
    title: {
        position: "absolute",
        width: "fit-content",
        margin: 0,
        top: 0,
        color: colors.supportBlack,
        fontSize: "34px",
        fontWeight: 700,
        fontFamily: "'Literata', serif",
        lineHeight: "43px",
        left:"20.45px"
        // color: colors.supportBlack,
        // fontSize: "34px",
        // fontWeight: 700,
        // fontFamily: "'Literata', serif",
        // lineHeight: "43px",
        // left:"20.45px"
    }
}