import React, {useEffect} from "react";
import LogoHeader from "./LogoHeader/LogoHeader";
import {style} from "./style";
import {NavLink, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import ProfileModal from "../profile/ProfileModal/ProfileModal";
import TokenManager from "../../../common/TokenManager";
import {useAppDispatch, useAppSelector} from "../../../common/Redux/hooks";
import {setUserPicture} from "../../../common/Redux/reducers/userPicture";
import {setAuthorizations} from "../../../common/Redux/reducers/userAuthorizations";

export default function Header() {
    const [profileVisibility, setProfileVisibility] = React.useState<boolean>(false)
    const [name, setName] = React.useState<string>("")
    const [picture, setPicture] = React.useState<string>("")
    const [loading, setLoading] = React.useState<boolean>(false)
    const navigate = useNavigate();
    const dispatch = useAppDispatch()
    const userPicture = useAppSelector((state) => state.userPicture.value)
    const userAuth = useAppSelector((state) => state.userAuthorizations.value)

    useEffect(() => {
        if (userPicture.length > 0)
            setPicture(userPicture)
    }, [userPicture])

    const getUserData = async () => {
        if (userPicture.length > 0)
            setPicture(userPicture)
        setLoading(true)
        const token = await TokenManager.getAccessToken()
        if (token === null)
            navigate("/login")
        let fetched = await fetch(`${process.env.REACT_APP_BASE_URL}/auth/user`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        })
        setLoading(false)
        if (!fetched.ok) {
            return
        }
        let user: {
            user_id: string,
            name: string,
            surname: string,
            email: string,
            job_title: string,
            company_name: string,
            profile_picture: string,
            interests: string[]
            authorizations: number[]
        } = await fetched.json()
        setName(`${user.name} ${user.surname}`)
        if (userPicture.length === 0)
            dispatch(setUserPicture(user.profile_picture))
        if (userAuth.length === 0)
            dispatch(setAuthorizations(user.authorizations.length === 0 ? [0] : user.authorizations))
        setPicture(user.profile_picture)
    }
    useEffect(() => {
        getUserData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (<>
            {profileVisibility ? <ProfileModal name={name} onClose={() => setProfileVisibility(false)}/> : null}
            <div style={style.header}>
                <div className={"d-flex"}>
                    <LogoHeader/>
                    <div style={style.linkBox}>
                        {userAuth.includes(30) && userAuth.length > 0 ?
                            <NavLink to={"discovery"} style={({isActive}) => isActive ? style.activeLink : style.link}>
                                Discovery
                            </NavLink>
                            : null}
                        {userAuth.includes(20) && userAuth.length > 0 ?
                            <NavLink to={"smart-targeting"}
                                     style={({isActive}) => isActive ? style.activeLink : style.link}>
                                Smart Targeting
                            </NavLink>
                            : null}
                        {userAuth.includes(10) && userAuth.length > 0 ?
                            <NavLink to={"media-analytics"}
                                     style={({isActive}) => isActive ? style.activeLink : style.link}>
                                Media Analytics
                            </NavLink>
                            : null}
                    </div>
                </div>
                <div className={"d-flex align-items-center"}>
                    {/*<NavLink to={"support"} style={({isActive}) => isActive ? style.supportActive : style.support}>*/}
                    {/*    <FontAwesomeIcon icon={faCircleQuestion} size={"lg"}/>*/}
                    {/*</NavLink>*/}
                    {picture.length > 0 ?
                        <img style={style.userPicture} src={picture} alt={""}
                             onClick={() => setProfileVisibility(true)} onError={() => setPicture("")}/>
                        : <div style={style.userIcon} onClick={() => setProfileVisibility(true)}>
                            {loading ? null : <FontAwesomeIcon icon={faUser} size={"lg"}/>}
                        </div>}
                </div>
            </div>
        </>
    );
}