import CSS from "csstype";
import {colors} from "../../../../../common/colors";

export const style: { [key: string]: CSS.Properties } = {
    box: {
        paddingLeft: "32px",
        paddingRight: "12px",
        height: "100%"
    },
    header: {
        display: "flex",
        flexDirection: "row",
        fontSize: "24px",
        fontWeight: "700",
        alignItems: "center",
        padding: "32px",
        boxSizing: "unset"
    },
    title: {
        fontSize: "24px",
        fontWeight: "700"
    },
    input: {
        height: "46px",
        width: "100%",
        outline: "none",
        borderRadius: "8px",
        border: `solid 1px ${colors.supportLight}`,
        padding: "12px 16px 12px 16px",
        marginBottom: "8px"
    },
    searchResults: {
        padding: "12px 16px 12px 16px",
        borderRadius: "8px",
        border: `solid 1px ${colors.supportLight}`,
        marginBottom: "16px",
        height: "calc(100% - 56px)",
        display: "flex",
        flexDirection: "column"
    },
    searchResultsTitle: {
        fontSize: "12px",
        fontWeight: "600",
        lineHeight: "16px",
        letterSpacing: "0em",
        textAlign: "left",
        margin: 0,
        color: colors.supportDark,
        marginBottom: "8px"
    },
    selected: {
        padding: "8px 12px 8px 12px",
        borderRadius: "8px",
        backgroundColor: colors.supportLighter,
        color: colors.supportBlack,
        fontWeight: 400,
        fontSize: "16px",
        marginBottom: "8px",
        cursor: "pointer",
        display: "inline-block",
        marginRight: "12px"
    },
    activeFilter: {
        // height: "46px",
        marginRight: "16px",
        marginBottom: "8px",
        display: "flex",
        alignItems: "center",
        float: "left",
        width: "fit-content",
        borderRadius: "8px",
        padding: "12px 16px 12px 16px",
        backgroundColor: "#f3f5f6",
        border: `solid 1px ${colors.supportLight}`
    },
    lastActiveFilter: {
        // height: "46px",
        marginRight: "16px",
        marginBottom: "50px",
        display: "flex",
        alignItems: "center",
        float: "left",
        width: "fit-content",
        borderRadius: "8px",
        padding: "12px 16px 12px 16px",
        backgroundColor: "#f3f5f6",
        border: `solid 1px ${colors.supportLight}`
    },
    authorSpan: {
        fontWeight: 600,
        fontSize: "12px",
        color: colors.supportDark
    },
    content: {
        height: "100%",
        width: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column"
    },
    scrollManager: {
        // height: "calc(100% - 102px)",
        // width: "100%",
        // overflowY: "scroll",
        paddingRight: "20px",
        boxSizing: "unset",
        flex: "1",
        overflow: "hidden",
        paddingBottom: "32px"
    },
    helpBox:{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent:"end",
        alignItems: "center",
    },
    helpText:{
        fontSize: "16px",
        fontWeight: 600,
        color: colors.supportDarker,
        cursor: "pointer",
        paddingRight: "8px"
    },
}