import React from "react";
import {style} from "../../../loginForm/components/LoginPointOutTitle/style";
// import background from "../../../../assets/svg/Vector.svg";
import logo from "../../../../assets/svg/Logo Point-out_solo logo_def_05.svg";

export default class LogoHeader extends React.Component<any, any> {
    render() {
        return (
            <div className={this.props.className} style={style.box}>
                <img src={logo} alt={""} style={style.logo}/>
                {/*<h3 style={style.title}>Point-out</h3>*/}
            </div>
        );
    }
}