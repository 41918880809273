import {style} from "./style";
import React, {useEffect} from "react";
import {ContentsResult} from "../discovery/type";
import pc from "../../../assets/png/pc.png";
import newspaper from "../../../assets/png/newspaper.png";
import {Row} from "react-bootstrap";
import email from "../../../assets/png/mail.png";
import linkedin from "../../../assets/png/linkedin.png";
import {useGetPublication} from "../discovery/Hooks/useGetContent";
import {colors} from "../../../common/colors";

type Props = {
    post: ContentsResult | undefined,
    close: () => void,
    discovery?: boolean
}

export default function ArticleOverlay({post, close, discovery = false}: Props) {
    const [authors, setAuthors] = React.useState<string>("")
    const {publication} = useGetPublication(post?.contentid ? post.contentid : -1)

    useEffect(() => {
        if (post !== undefined && post.authors) {
            let authors = ""
            let authorsLength = post.authors?.length ? post.authors?.length : -1
            for (let i = authorsLength - 1; 0 <= i; i--) {
                authors = ""
                switch (i) {
                    case 0:
                        authors += `${post.authors[i].name} `
                        break
                    case 1:
                        authors += `${post.authors[i].name} e `
                        break
                    default:
                        authors += `${post.authors[i].name}, `
                        break
                }
            }
            setAuthors(authors)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    if (post === undefined)
        return null
    return (
        <div style={discovery ? style.box : style.box2}>
            <div style={discovery ? style.modal : style.modal2}>
                <div style={post.url ? style.row : style.noMarginRow}>
                    <a target="_blank" rel={"noreferrer"}
                       href={post.url ? post.url : undefined}>{post.url ? "Link all'articolo" : null}</a>
                    <button style={style.button} onClick={close}>{"Chiudi"}</button>
                </div>
                <div style={{display: "flex", justifyContent: "space-between",}}>
                    <div className={"d-flex"}>
                        <div style={style.icon}>
                            {post.mediaType.name === "Stampa digitale" ?
                                <img src={pc} alt={""} style={{width: "25px", height: "auto"}}/>
                                : <img src={newspaper} alt={""} style={{width: "25px", height: "auto"}}/>}
                        </div>
                        <div className={"flex-column"}>
                            <Row style={{
                                paddingRight: "12px",
                                display: "block"
                            }}>
                                <span style={style.mediaType}>{post.mediaType.name}</span>
                                <span style={style.titleType}>{post.titleType.name}</span>
                                {post.topics && post.topics.length > 0 ?
                                    post.topics.map((topic, index) =>
                                        <span key={`topic-${post.contentid}-${index}`}
                                              style={style.topic}>{topic}</span>)
                                    : null}
                                {publication?.geoCategory?.name ?
                                    <span style={style.geo}>{publication?.geoCategory?.name}</span>
                                    : null}
                            </Row>
                        </div>
                    </div>
                </div>
                <div style={style.header}>
                    <h1 style={style.source}>{post.source.name}</h1>
                    <h3 style={style.publicationDate}>{post.publicationDate}</h3>
                </div>
                <div style={style.content}>
                    <h3 style={style.title}>{post.title}</h3>
                    <div style={style.author}>
                        {post.authors && post.authors?.length > 0 ?
                            <>{"di "}<span style={{...style.bold, paddingLeft: "6px"}}>
                            {` ${authors}`}
                        </span>
                            </>
                            : null}
                        {publication?.authors && publication?.authors[0].emails && publication?.authors[0].emails[0] ?
                            <div className={"verticalCenter"} style={style.social}>
                                <img style={style.img} src={email} alt={""}/>
                                <a href={`mailto:${publication?.authors[0].emails[0]}`}
                                   style={style.text} target="_blank"
                                   rel={"noreferrer"}>{publication?.authors[0].emails[0]}</a>
                            </div>
                            : null}
                        {publication?.authors && publication?.authors[0].linkedin?.name ?
                            <div className={"verticalCenter"} style={style.social}>
                                <img style={style.img} src={linkedin} alt={""}/>
                                <a href={`https://www.linkedin.com/in/${publication?.authors[0].linkedin.name}/`}
                                   style={style.text} target="_blank"
                                   rel={"noreferrer"}>{publication?.authors[0].linkedin.name}</a>
                            </div>
                            : null}
                        {publication?.authors && publication?.authors[0].twitter?.name ?
                            <div className={"verticalCenter"} style={style.social}>
                                {/*<img style={style.img} src={twitter} alt={""}/>*/}
                                {/*! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc.*/}
                                <div style={style.img}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill={"#3d474a"} height="1em"
                                         viewBox="0 0 512 512">
                                        <path
                                            d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/>
                                    </svg>
                                </div>
                                <a href={`https://twitter.com/${publication?.authors[0].twitter.name.replace("@", "")}/`}
                                   style={style.text} target="_blank"
                                   rel={"noreferrer"}>{publication?.authors[0].twitter.name}</a>
                            </div>
                            : null}
                    </div>
                    <p style={style.snippet}>{publication?.body ? publication.body : post.snippet}</p>

                </div>
                {publication?.topics && publication?.topics.length > 0 ?
                    <>
                        <div style={style.topicBox}>
                            <div style={{backgroundColor: colors.supportWhite, height: "100%"}}>
                                <h3 style={style.topicTitle}>Topic</h3>
                                <div style={style.topicList}>
                                    {publication.topics.map((topic, index) =>
                                        <span key={`topic-${post.contentid}-${index}`}
                                              style={style.topic}>{topic}</span>)}
                                </div>
                            </div>
                        </div>
                    </>
                    : null}
            </div>
        </div>
    )
}