import CSS from "csstype";
import {colors} from "../../../common/colors";

export const style: { [key: string]: CSS.Properties } = {
    box: {
        width: "100%",
        height: "calc(100% - 80px)",
        marginTop: "80px",
        position: "fixed",
        top: 0,
        backgroundColor: "rgba(29,29,27,0.3)",
        zIndex: 10000,
        fontFamily: "Literata"
    },
    box2: {
        width: "85%",
        height: "calc(100% - 80px)",
        marginTop: "80px",
        position: "fixed",
        top: 0,
        right: 0,
        zIndex: 10000,
        borderTopLeftRadius: "16px",
        borderBottomLeftRadius: "16px",
        boxShadow: "0px 10px 61px 22px #0000001f"
    },
    header: {
        paddingTop: "36px",
        borderBottom: `3px solid ${colors.supportDarker}`,
        paddingLeft: "12px",
    },
    source: {
        fontSize: "30px",
        fontWeight: 700,
        margin: 0,
        color: colors.supportBlack
    },
    publicationDate: {
        paddingTop: "24px",
        paddingBottom: "4px",
        fontSize: "18px",
        fontWeight: 400,
        margin: 0,
        color: colors.supportBlack,
    },
    icon: {
        backgroundColor: colors.primaryLight,
        borderRadius: "50px",
        height: "60px",
        width: "60px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: "10px",
        padding: "0 11px"
    },
    modal: {
        height: "100%",
        marginLeft: "10%",
        padding: "40px 30px 40px 38px",
        backgroundColor: colors.supportWhite,
        overflowY: "scroll",
        borderTopLeftRadius: "16px",
        borderBottomLeftRadius: "16px",
        position: "relative"
    },
    modal2: {
        height: "100%",
        padding: "40px 30px 40px 38px",
        backgroundColor: colors.supportWhite,
        overflowY: "scroll",
        borderTopLeftRadius: "16px",
        borderBottomLeftRadius: "16px",
    },
    row: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "32px"
    },
    noMarginRow: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    button: {
        height: "46px",
        padding: "12px 16px 12px 16px",
        borderRadius: "8px",
        border: `1px solid ${colors.supportLight}`,
        backgroundColor: "white",
        boxShadow: "0px 2px 1px 0px #1018280D",
        display: "flex",
        alignItems: "center"
    },
    footer: {
        fontSize: "16px",
        fontWeight: 400,
        margin: 0,
    },
    bold: {
        fontSize: "16px",
        fontWeight: 600,
        margin: 0,
    },
    content: {
        paddingLeft: "12px",
    },
    title: {
        paddingTop: "40px",
        paddingBottom: "40px",
        fontSize: "24px",
        fontWeight: 400,
        margin: 0,
        // width: "90%",
        color: colors.supportBlack
    },
    author: {
        display: "flex",
        flexDirection: "row",
    },
    mediaType: {
        padding: "2px 4px",
        paddingBottom: "4px",
        backgroundColor: colors.primaryLight,
        borderRadius: "5px",
        marginLeft: "12px"
    },
    titleType: {
        padding: "2px 4px",
        paddingBottom: "4px",
        backgroundColor: colors.magenta,
        color: colors.supportWhite,
        borderRadius: "5px",
        marginLeft: "12px"
    },
    social: {
        paddingLeft: "60px",
        marginBottom: "8px",
        overflowWrap: "break-word",
    },
    text: {
        fontWeight: 400,
        fontSize: "14px",
        color: colors.supportDarker
    },
    textCenterVertically: {
        height: "100%",
        display: "flex",
        alignItems: "center"
    },
    geo: {
        padding: "2px 4px",
        paddingBottom: "4px",
        backgroundColor: colors.cyan,
        color: colors.supportWhite,
        borderRadius: "5px",
        marginLeft: "12px",
    },
    img: {
        width: "18px",
        height: "auto",
        marginRight: "4px",
        // marginLeft: "13px"
    },
    topic: {
        padding: "2px 4px",
        paddingBottom: "4px",
        backgroundColor: colors.darkGray,
        color: colors.supportWhite,
        borderRadius: "5px",
        marginRight: "12px"
    },
    topicTitle: {
        paddingLeft: "12px",
        fontSize: "24px",
        fontWeight: 400,
        margin: 0,
        paddingBottom: "8px",
        color: colors.supportBlack,
        borderBottom: `3px solid ${colors.supportDarker}`,
    },
    snippet: {
        // paddingTop: "24px",
    },
    topicBox: {
        paddingTop: "50px",
        paddingBottom: "50px",
    },
    topicList: {
        display: "flex",
        paddingTop: "8px",
        flexWrap: "wrap",
        gap: "10px"
    }
}