import CSS from "csstype";
import {colors} from "../../../../../common/colors";

export const style: { [key: string]: CSS.Properties } = {
    box: {
        border: `solid 1px ${colors.supportLight}`,
        borderRadius: "16px",
        padding: "24px",
        boxShadow: "0px 3px 10px 0px #3636361C",
        marginBottom: "24px"
    },
    icon: {
        backgroundColor: colors.primaryLight,
        borderRadius: "20px",
        height: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: "10px",
        padding: "0 11px"
    },
    title: {
        fontSize: "20px",
        fontWeight: 700,
        margin: 0,
        // width: "90%",
        color: colors.supportBlack
    },
    content: {
        fontSize: "16px",
        fontWeight: 400,
        margin: 0,
        marginBottom: "16px"
    },
    footer: {
        fontSize: "16px",
        fontWeight: 400,
        margin: 0,
    },
    bold: {
        fontSize: "16px",
        fontWeight: 600,
        margin: 0,
    },
    topic: {
        padding: "2px 4px",
        // paddingLeft: "6px",
        // paddingTop: "2px",
        // paddingBottom: "6px",
        // paddingRight: "8px",
        backgroundColor: colors.darkGray,
        color: colors.supportWhite,
        borderRadius: "5px",
        marginLeft: "12px",
        // fontWeight: "bold",
    },
    mediaType: {
        padding: "2px 4px",
        backgroundColor: colors.primaryLight,
        borderRadius: "5px",
        marginLeft: "12px"
    },
    titleType: {
        padding: "2px 4px",
        // backgroundColor: "#ee9a3a",
        backgroundColor: colors.magenta,
        color: colors.supportWhite,
        borderRadius: "5px",
        marginLeft: "12px"
    }
}