export const colors = {
    primaryLight: "#F7E400",
    primaryDark: "#F4CD01",
    primaryDarker: "#c7a800",
    primaryLightOpacity: "rgba(247,228,0,0.4)",
    supportBlack: "#1D1D1B",
    supportLight: "#CED4DA",
    supportLighter: "#F3F5F6",
    supportWhite: "#FFFFFF",
    supportDark: "#99A6B2",
    supportDarker: "#3D474A",
    supportDanger: "#ff0e0e",
    darkGray: "#3d474a",
    darkGrayOpacity: "rgba(61,71,74,0.4)",
    magenta: "#d92d8a",
    cyan: "#00aae9",
}