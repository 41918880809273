import React from "react";
import {style} from "../SearchAuto/style";
import {Col} from "react-bootstrap";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";
import {colors} from "../../../../../common/colors";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export type KeywordFilterProps = {
    title: string,
    filterName: string,
    selected: string[],
    setSelected: (filterName: string, selectedFilters: string[]) => void,
    openHelp: () => void
}
export const KeywordFilter = ({title, filterName, setSelected, selected, openHelp}: KeywordFilterProps) => {
    const [searchingWord, setSearchingWord] = React.useState<string>("")

    const setWord = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchingWord(event.target.value)
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && (event.target as HTMLInputElement).value as string !== "") {
            if (!selected.includes((event.target as HTMLInputElement).value)) {
                setSelected(filterName, [...selected, (event.target as HTMLInputElement).value])
            }
            setSearchingWord("")
        }
    }

    return (
        <div style={style.content}>
            <div style={style.header}>
                <h3 style={style.title}>{title}</h3>
            </div>
            <div style={style.scrollManager}>
                <div style={style.box}>
                    <Col style={{height: "100%"}}>
                        <input type={"text"} style={style.input}
                               placeholder={""}
                               value={searchingWord}
                               onChange={setWord}
                               onKeyDown={handleKeyDown}/>
                        <div style={style.helpBox}>
                            <div style={style.helpText} onClick={openHelp}>{"Aiuto"}</div>
                            <FontAwesomeIcon onClick={openHelp} icon={faAngleRight} color={colors.supportDarker}/>
                        </div>
                    </Col>
                </div>
            </div>
        </div>
    );
}