import CSS from "csstype";
import {colors} from "../../../common/colors";

export const style: { [key: string]: CSS.Properties } = {
    box: {
        height: "calc(100% - 80px)",
        width: "400px",
        borderRight: `1px solid ${colors.supportLight}`,
        boxShadow: "0px 3px 10px 0px #3636361C",
        position: "fixed",
        overflow: "hidden",
        backgroundColor: "white",
    },
    content: {
        // height: "100%",
        // width: "100%",
        overflow: "auto",
        paddingRight: " 52px",
        padding: " 32px",
        boxSizing: "unset",
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: "-17px",
        overflowY: "scroll",
    },
    header: {
        height: "38px",
        display: "flex",
        flexDirection: "row",
        // padding: "32px",
        justifyContent: "space-between",
        alignItems: "center",
    },
    title: {
        fontWeight: 700,
        fontSize: "24px",
        alignItems: "center",
        justifyContent: "space-between",
    },
    loadSave: {
        fontSize: "16px",
        fontWeight: 600,
        padding: "8px 16px 8px 16px",
        borderRadius: "8px",
        backgroundColor: colors.supportWhite,
        border: `solid 1px ${colors.supportLight}`
    },
    table: {
        width: "100%",
        paddingLeft: "32px",
        paddingRight: "32px",
    },
    filterRow: {
        width: "100%",
        display: "flex",
        height: "59px",
        paddingLeft: "10px",
        alignItems: "center",
        justifyContent: "space-between",
        // borderBottom: `solid 1px ${colors.supportLight}`,
        cursor: "pointer",
    },
    filterRowActive: {
        width: "100%",
        display: "flex",
        height: "39px",
        marginBottom: "10px",
        paddingLeft: "10px",
        marginTop: "10px",
        alignItems: "center",
        justifyContent: "space-between",
        // borderBottom: `solid 1px ${colors.supportLight}`,
        cursor: "pointer",
        backgroundColor: colors.primaryLight
    },
    filterRowWithFilters: {
        width: "100%",
        display: "flex",
        height: "39px",
        marginBottom: "10px",
        paddingLeft: "10px",
        marginTop: "10px",
        alignItems: "center",
        justifyContent: "space-between",
        cursor: "pointer",
    },
    filterRowWithFiltersActive: {
        width: "100%",
        display: "flex",
        height: "39px",
        marginBottom: "10px",
        paddingLeft: "10px",
        marginTop: "10px",
        alignItems: "center",
        justifyContent: "space-between",
        cursor: "pointer",
        backgroundColor: colors.primaryLight
    },
    filterName: {
        fontWeight: 700,
        fontSize: "18px"
    },
    viewAllButton: {
        border: "none",
        backgroundColor: "transparent",
        fontFamily: "'Literata', serif",
    },
    activeFilterRow: {
        borderBottom: `solid 1px ${colors.supportLight}`
    },
    activeFilter: {
        marginRight: "16px",
        marginBottom: "16px",
        display: "flex",
        alignItems: "center",
        float: "left",
        width: "fit-content",
        borderRadius: "8px",
        padding: "12px 16px 12px 16px",
        backgroundColor: "#f3f5f6",
        border: `solid 1px ${colors.supportLight}`
    },
    authorSpan: {
        fontWeight: 600,
        fontSize: "12px",
        color: colors.supportDark
    },
    resetFilters: {
        backgroundColor: colors.supportLighter,
        borderRadius: "8px",
        height: "38px",
        width: "38px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
    },
    search: {
        border: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: colors.primaryLight,
        cursor: "pointer",
        borderRadius: "8px",
        marginLeft: "8px",
        padding: "0 12px 0 12px",
        fontWeight: 600
    },
    saveFilters: {
        marginTop: "8px",
        marginRight: "8px",
        width: "calc(40% - 4px)",
        padding: "12px 16px 12px 16px",
        borderRadius: "8px",
        boxShadow: "0px 2px 1px 0px #1018280D",
        fontSize: "16px",
        fontWeight: 600,
        color: colors.supportBlack,
        border: "none",
        backgroundColor: colors.supportLighter
    },
    searchButton: {
        marginTop: "8px",
        width: "calc(60% - 4px)",
        padding: "12px 16px 12px 16px",
        borderRadius: "8px",
        boxShadow: "0px 2px 1px 0px #1018280D",
        fontSize: "16px",
        fontWeight: 600,
        // color: colors.supportBlack,
        color: colors.supportWhite,
        border: "none",
        // backgroundColor: colors.primaryLight
        backgroundColor: colors.darkGray
    },
    searchButtonDisabled: {
        marginTop: "8px",
        width: "calc(60% - 4px)",
        padding: "12px 16px 12px 16px",
        borderRadius: "8px",
        boxShadow: "0px 2px 1px 0px #1018280D",
        fontSize: "16px",
        fontWeight: 600,
        // color: colors.supportBlack,
        color: colors.supportWhite,
        border: "none",
        // backgroundColor: colors.primaryLightOpacity
        backgroundColor: colors.darkGrayOpacity

    },
    helpBox: {
        padding: "32px",
        lineHeight: "2",
    },
    helpTitle: {
        fontSize: "20px",
        fontWeight: 700,
        marginBottom: "16px"
    },
    helpBold: {
        fontWeight: 700,
        fontSize: "14px",
        paddingBottom: "16px"
    },
    helpNormal: {
        fontSize: "16px",
        fontWeight: 400
    },
    helpCode: {
        backgroundColor: colors.supportLighter,
        padding: "4px 8px 4px 8px",
        borderRadius: "8px",
        fontSize: "14px",
        fontFamily: "monospace",
        display: "inline-block",
        fontWeight: 400,
        border: `solid 1px ${colors.supportLight}`
    }
}